<template>
  <div
    v-loading="loading"
    class="interests-page"
  >
    <div class="title">
      <h2>Your interests</h2>
    </div>
    <div class="desc">
      <span>To help you get the most from {{ brand.name }}, select the tags below that appeal to you. The content you will receive will relate to these chosen interests.</span>
    </div>
    <div class="tags">
      <div class="title">
        <span>Minimum of 2 tags</span>
      </div>
      <div
        v-if="tags"
        class="list"
      >
        <el-tag
          v-for="tag in tags"
          :key="tag.id"
          :class="{ active: tag.enabled }"
          @click.native="tagClick(tag)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
      <div class="save-btn">
        <button
          class="fgh-button lg fgh-button--primary"
          :disabled="loading"
          @click.prevent="saveUpdate"
        >
          Save updates
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import accountApi from '@/modules/account/services/account.api'

export default {
  name: "InterestsPage",

  data () {
    return {
      tags: [],
      loading: false
    }
  },

  created() {
    this.getTags()
  },

  methods: {
    getTags() {
      this.loading = true
      accountApi
        .tags()
        .then((response) => {
          this.loading = false
          this.tags = response.data.data
        })
    },
    tagClick (tag) {
      tag.enabled = !tag.enabled
    },
    saveUpdate() {
      if (this.tags.filter(tag => tag.enabled).length < 2) {
        setTimeout(() =>{
          this.$notify.error({
            title: 'Error',
            message: 'At least 2 tags must be selected.'
          })
        }, 500)
      } else {
        this.loading = true
        accountApi
          .updateTags({ tags: this.tags })
          .then((response) => {
            this.loading = false
            this.$notify.success({
              title: 'Success',
              message: response.data.message
            })
          })
      }
    }
  }
}
</script>

<style lang="scss">
.interests-page {
  background-color: $white-color;
  width: 60%;
  margin: 37px auto 66px auto;
  padding: 24px 30px 33px 30px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05), 0px 0px 21px rgba(0, 0, 0, 0.07);

  .el-loading-mask {
    background-color: rgba(255,255,255,.9);
  }

  @media screen and (max-width: 1440px) {
    width: 60%;
  }
    @media screen and (max-width: 1024px) {
    width: auto;
  }
  .title  {
    h2 {
      margin: 0;
    }
  }
  .desc {
    font-size: 16px;
    margin-top: 25px;
  }
  .tags {
    margin-top: 30px;
    .title {
      text-transform: inherit;
      font-size: 14px;
    }
    .list {
      margin-top: 20px;
      .el-tag {
        background-color: $white-color;
        height: 26px;
        line-height: 26px;
        margin-right: 8px;
        margin-bottom: 8px;
        border-radius: 16px;
        border-color: $body-text-color;
        color: $body-text-color;
        cursor: pointer;
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
        &.active {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $white-color;
        }
      }
    }
    .save-btn {
      margin-top: 83px;
      text-align: right;
    }
  }
}
</style>
